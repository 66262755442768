<template>
    <v-app>
        <Home></Home>
    </v-app>
</template>

<script>
import { loadScript } from "vue-plugin-load-script";
import Home from "./pages/HomePage";
export default {
    name: 'App',

    components: {
        Home,
    },

    data: () => ({
        //
    }),
    mounted() {
        loadScript("/js/core.min.js").then(()=>{
            loadScript("/js/script.js")
        })

    }
};
</script>
<style>
 * {
     --btn-color: #89B951;
 }


a {
    color: var(--btn-color);
}

button {
    background-color: var(--btn-color);
    color: white;
    padding: 12px 23px;
    border: 1px solid transparent;
    margin: 5px;
    border-radius: 6px;
    text-transform: uppercase;
    font-size: .75rem;
    font-family: Roboto, sans-serif;
    font-weight: bold;
}

 .img-thumbnail-variant-1 > img , .caption {
     cursor: pointer;
 }

</style>
