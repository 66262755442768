<template>
    <div class="pa-0 ma-0">
        <v-col cols="12"class="rounded-lg">
            <v-row>
                <v-toolbar class="" color="var(--btn-color)" dark>
                    <v-row justify="center" align="center">
                        <span style="font-size: 1.2rem">Se connecter à la gestion des promotions</span>
                    </v-row>
                </v-toolbar>
            </v-row>
            <v-row justify="center" class="ml-4">
                <v-col cols="9">
                    <v-row justify="center" class="mt-3">
                        <v-alert
                                dense
                                outlined
                                type="error"
                                v-if="response.authentification==false"
                        >
                            Identifiant ou mot de passe incorrecte
                        </v-alert>
                        <v-alert
                                dense
                                outlined
                                type="success"
                                v-if="$store.getters.authenticateCentre"
                                class="my-3"
                        >
                            Vous êtes authentifié
                        </v-alert>
                    </v-row>
                </v-col>
            </v-row>
            <v-form class="my-4 rounded-sm pa-3 pt-0 mt-0" v-if="!$store.getters.authenticateCentre">

                <v-row justify="center">
                    <v-col cols="9" class="mt-5 mb-0 pb-0">
                        <v-text-field
                                :prepend-icon="icons.mdiAccountBox"
                                v-model="login"
                                filled
                                clear-icon="mdi-close-circle"
                                clearable
                                label="Saisir votre identifiant"
                                type="text"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row justify="center" class="mb-0 pb-0">
                    <v-col cols="9" class="mb-0 pb-0">
                        <v-text-field
                                class="my-0 py-0"
                                :prepend-icon="icons.mdiLock"
                                v-model="password"
                                type="password"
                                filled
                                clear-icon="mdi-close-circle"
                                clearable
                                label="Saisir votre mot de passe"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row justify="center" class="mt-0 pt-0">
                    <v-col cols="4" class="mt-0 pt-0">
                        <b-button  @click="connexion" style="color:white" class="btn btn-sushi btn-sm mr-2 rounded-pill">Connexion</b-button>
                    </v-col>
                </v-row>
            </v-form>
        </v-col>
    </div>
</template>

<script>
    import { mdiLock,mdiAccountBox } from '@mdi/js';
    export default {
        name: "login-centre",
        data(){
            return{
                response:'',
                show4:false,
                password: '',
                show: false,
                login:'',
                marker: true,
                iconIndex: 0,
                icons:{mdiAccountBox,mdiLock}
            }
        },
        methods:{
            async connexion(){
                this.response=''
                const object = {
                    login:this.login,
                    password: this.password
                }
                this.response= await this.$store.dispatch("loginCentre",object)
                if(this.response.authentification){
                    this.$emit('closeDialog')
                    this.$router.push({name:'espace-centre'})
                }
            },
        },
    }
</script>

<style scoped>

</style>
