import { render, staticRenderFns } from "./SwapStatusClient.vue?vue&type=template&id=62818a45&scoped=true&"
import script from "./SwapStatusClient.vue?vue&type=script&lang=js&"
export * from "./SwapStatusClient.vue?vue&type=script&lang=js&"
import style0 from "./SwapStatusClient.vue?vue&type=style&index=0&id=62818a45&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62818a45",
  null
  
)

export default component.exports