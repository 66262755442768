import Vue from "vue";
import App from './App.vue';

import { professionalRoutes } from "./routes";
import { professionalStore } from "./store";
import Vuex from 'vuex';
import VueRouter from "vue-router"
import vuetify from "./plugins/vuetify";
import vuemeta from 'vue-meta'



//GoogleMaps
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps,{
  load: {
    key: 'AIzaSyBs9p5zKlf3sCH5w62p-VAG9dzwzmlLEyw',
    region: 'FR',
    language: 'fr',
  },

})

Vue.use(Vuex)
Vue.use(VueRouter)
//Vue.use(colorsHandler)
Vue.use(vuemeta)
const routes = professionalRoutes
const store = new Vuex.Store(professionalStore)
const router = new VueRouter({
  mode: 'history',
  routes
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
