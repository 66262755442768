import axios from "axios";
export const professionalStore = {
    state:{
        applicationName: "Autobilan-systems",
        authenticateCentre:false,
        tokenCentre:'',
        centresEspace:'',
        centrePicked:'',
        prestationPicked:'',
        counterEspace:0,
        promotionPicked:null
    },
    getters:{
        appName: state => state.applicationName,
        tokenCentre: state => state.tokenCentre,
        authenticateCentre: state => state.authenticateCentre,
        centresEspace: state => state.centresEspace,
        centrePicked:state=> state.centrePicked,
        prestationPicked : state => state.prestationPicked,
        counterEspace:state=>state.counterEspace,
        promotionPicked:state=>state.promotionPicked
    },
    mutations:{
        setPromotionPicked(state,object){
          state.promotionPicked= object
        },
        incrementCounter(state){
          state.counterEspace +=1
        },
        setPromotionPrestation(state,data){
          state.prestationPicked.promotions = data
        },
        setPrestationPicked(state,data){
          state.prestationPicked= data
        },
        setCentrePicked(state,data){
          state.centrePicked= data
        },
        setTokenCentre(state,data){
            state.tokenCentre = data
            state.authenticateCentre = true
            localStorage.setItem("tokenCentre",data)
        },
        setAuthCentre(state,centres){
            state.centresEspace = centres
        },
        deconnectCentre(state){
            state.tokenCentre=''
            localStorage.removeItem("tokenCentre")
            state.authenticateCentre= false
            state.centresEspace =''
        },
        setAuthenticateCentre(state,data){
            state.authenticateCentre = data
        }
    },
    actions:{
        async loginCentre(state,object){
            const {centres,authentification,token} = (await axios.post(`${process.env.VUE_APP_CURRENT_URL}login-centre`,object)).data
            if(authentification){
                state.commit("setTokenCentre",token)
                state.commit("setAuthCentre",centres)
            }

            return {authentification:authentification}
        },
        async setPromotion(state,object){
            const response = (await axios.post(`${process.env.VUE_APP_CURRENT_URL}set-promotion`,object))
            return response.data
        },
        async getPromotions(state,object){
            const response = (await axios.post(`${process.env.VUE_APP_CURRENT_URL}get-promotions`,object))
            return response.data
        },
        async deletePromotion(state,object){
            const response = (await axios.post(`${process.env.VUE_APP_CURRENT_URL}delete-promotion`,object))
            return response.data
        },
        async getDataCentre(state,token){
             
            try {
                const config ={
                    headers:{
                        'Authorization' : `Bearer ${token}`
                    }
                }
                
                const result = (await axios.get(`${process.env.VUE_APP_CURRENT_URL}centre-data`,config)).data
                state.commit("setAuthCentre",result.centres)
                state.commit("setAuthenticateCentre",true)
                console.log(result)
                return result
            }catch (e) {
                
                
                state.commit('deconnectCentre')
                return {data:false}
            }
        }
    }
}
