<template>
    <!-- Page Header-->
    <v-footer class="page-foot bg-gray py-4" style="background-color: #EEF0F2">
        <v-dialog
            v-model="dialogLoginCentre"
            width="500"
        >
            <v-card class="pa-0 ma-0">
                <loginCentre @closeDialog="closeDialog"></loginCentre>
            </v-card>
        </v-dialog>
        <v-row justify="center" class="pa-0 my-4">
            <v-col class="footer-content" cols="10" lg="11" xl="10">
                <v-row justify="space-around">


                    <!--                 addresse/logo-->
                    <v-col cols="12" sm="6" md="4" lg="2" xl="2">
                        <v-row class="rd-navbar-brand">
                            <v-col cols="12">
                                <router-link :to="'/'">
                                    <img alt="logo autobilan-systems" src="/images/AUTOBILAN_logo.png">
                                </router-link>
                            </v-col>
                        </v-row>
                        <v-row class="mt-1" justify="start">
                            <v-col cols="12">
                                <p class="ma-0 pa-0">
                                    Arteparc – Bât B
                                    Route de la Côte d’Azur
                                    Lieu dit Le Canet de Meyreuil
                                </p>
                                <p class="text-justify ma-0 pa-0">
                                    13590 MEYREUIL
                                </p>
                                <p class="text-justify ma-0 pa-0">
                                    <a href="callto:#" v-add-class-hover="'hoverClass'" class="text-primary">05 63 73 16 97</a>
                                </p>
                                <p class="text-justify ma-0 pa-0">
                                    <a href="mailto:#" v-add-class-hover="'hoverClass'" class="text-primary">contact@autobilan-systems.com</a>
                                </p>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="my-0">
                            <v-col class="col-12">
                                <button v-on:click="redirectContact" style="color:white;background-color: var(--btn-color)!important;" class="btn btn-sm rounded-pill" >
                                    Nous Contacter
                                </button>
                            </v-col>
                        </v-row>
                    </v-col>

                    <!--                  content 1 2-->
                    <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                        <h6 class="text-ubold my-0 py-0">Les centres de contrôle</h6>
                        <ul class="list-marked well6 text-left mt-2 py-0">
                            <li>
                                <a v-add-class-hover="'hoverClass'" v-on:click="redirectToRecruit">Postuler pour un poste de contrôleur technique</a>
                            </li>
                        </ul>

                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="2">
                        <h6 class="text-ubold my-0 py-0">Professionnel</h6>
                        <ul class="list-marked well6 text-left mt-2 py-0">
                            <li>
                                <router-link v-bind:to="{name:'vehiculesLegers', params: { id:'articles' , art: true } }">
                                    <a v-add-class-hover="'hoverClass'" href="">Véhicules légers</a>
                                </router-link>
                            </li>
                            <li>
                                <router-link v-bind:to="{name:'poidsLourds', params: { id:'articles', art: true } }">
                                    <a v-add-class-hover="'hoverClass'" href="">Poids lourds</a>
                                </router-link>
                            </li>
                            <li>
                                <router-link v-bind:to="{name:'categoriel', params: { id:'articles', art: true } }">
                                    <a v-add-class-hover="'hoverClass'" href="">Catégorie L</a>
                                </router-link>
                            </li>
                            <li>
                                <router-link v-bind:to="{name:'audits', params: { id:'articles' , art: true } }">
                                    <a v-add-class-hover="'hoverClass'" href="">Audit</a>
                                </router-link>
                            </li>
                            <li>
                                <router-link v-bind:to="{name:'formations', params: { id:'articles' , art: true } }">
                                    <a v-add-class-hover="'hoverClass'" href="">Formation</a>
                                </router-link>
                            </li>
                            <li>
                                <router-link v-bind:to="{name:'informatique', params: { id:'articles' , art: true } }">
                                    <a v-add-class-hover="'hoverClass'" href="">Informatique</a>
                                </router-link>
                            </li>
                            <li>
                                <router-link v-bind:to="{name:'qui-sommes-nous', params: { id:'articles' , art: true } }">
                                    <a v-add-class-hover="'hoverClass'" href="">Qui sommes-nous ?</a>
                                </router-link>

                            </li>
                        </ul>
                    </v-col>


                    <!--                 content 4-->
                    <v-col cols="12" sm="6" md="4" lg="2"  order="1" order-md="2" order-lg="1">
                        <v-row>
                            <v-col>
                                <h6 class="text-ubold my-0 py-0">Informations légales</h6>
                                <ul class="list-marked well6 text-left mt-2 py-0">
                                    <li>
                                        <router-link to="/informations-legales/CGU">
                                            <a v-add-class-hover="'hoverClass'" href="">Conditions Générales d'Utilisation</a>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link to="/informations-legales/politiques-des-cookies">
                                            <a v-add-class-hover="'hoverClass'" href="">Utilisations des cookies</a>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{path:'/informations-legales/mentions-Legales'}">
                                            <a v-add-class-hover="'hoverClass'" href="">Mentions légales</a>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link to="/informations-legales/RGPD">
                                            <a v-add-class-hover="'hoverClass'" href="">RGPD</a>
                                        </router-link>
                                    </li>
                                </ul>
                            </v-col>
                        </v-row>
                    </v-col>
                  <v-col cols="12" sm="6" md="4" lg="2"  order="1" order-md="2" order-lg="1">
                    <v-row>
                      <v-col>
                        <h6 class="text-ubold my-0 py-0">Actualités</h6>
                        <ul class="list-marked well6 text-left mt-2 py-0">
                          <li>
                            <router-link to="/actualites/tout-controle">
                              Tout savoir sur le contrôle technique
                            </router-link>
                          </li>
                          <li>
                            <router-link to="/actualites/tout-controle-cl">
                              Tout savoir sur le contrôle technique catégorie L
                            </router-link>
                          </li>
                          <li>
                            <router-link to="/actualites/autobil-info">
                              AutoBilan-Systems - Le contrôle technique pour les indépendants
                            </router-link>
                          </li>
                          <li>
                            <router-link to="/actualites/audit-init-vlpl">
                              Audits initiaux pour centres VL et PL avec AutoBilan-Systems
                            </router-link>
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                    <v-row justify="center" class="my-0">
                      <v-col class="col-12">
                        <a href="/actualites" style="color:white;background-color: var(--btn-color)!important;" class="btn btn-sm rounded-pill" >
                          Voir toutes les actualités
                        </a>
                      </v-col>
                    </v-row>
                  </v-col>
                    <!-- <v-col cols="12" sm="6" md="3" lg="2" order-md="5" order-lg="5">
                        <h6 class="text-ubold my-0 py-0">Administration</h6>
                        <ul class="list-marked well6 text-left mt-2 py-0">
                            <li>
                                <a  v-on:click="espaceCentre" v-add-class-hover="'hoverClass'">Gestion des promotions</a>
                            </li>
                        </ul>
                    </v-col> -->

                    <v-col cols="3" order="3" v-if="$vuetify.breakpoint.mdOnly"></v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="justify-center">
            <v-col class="col-10">
                <v-row>
                    <v-col class="col-8 ml-8">
                        <v-row justify="start">
                            <p class="pull-sm-left">©
                                <span id="copyright-year"></span> All Rights Reserved
                            </p>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
import loginCentre from "@/components/espace-centre/login-centre"
import colorsHandler from "../libraries/colorsHandler"
export default {
    name: "FooterComponent",
    components:{
        loginCentre
    },
    data() {
        return {
            dialogLoginCentre:false,
            switch2: true,
            root:null
        }
    },
    mounted(){
        this.root = document.documentElement
    },
    directives:{
        'add-class-hover':{
            bind(el, binding, vnode) {
                const { value="" } = binding;
                el.addEventListener('mouseenter',()=> {
                    el.classList.add(value)
                });
                el.addEventListener('mouseleave',()=> {
                    el.classList.remove(value)
                });
            },
            unbind(el, binding, vnode) {
                /* el.removeEventListener('mouseenter');
                 el.removeEventListener('mouseleave')*/
            }
        }
    },
    methods: {
        redirectToRecruit(){
            window.location.href = process.env.VUE_APP_URL_PARTICULIER+"/recruit"
        },
        closeDialog(){
            this.dialogLoginCentre= false
        },
        espaceCentre(){
            if(this.$store.getters.authenticateCentre){
                if(this.$route.name !== 'espace-centre')
                    this.$router.push({name:'espace-centre'})
                this.dialogLoginCentre = false
            }else
                this.dialogLoginCentre = true
        },
        espaceCentre(){
            if(this.$store.getters.authenticateCentre){
                if(this.$route.name !== 'espace-centre')
                    this.$router.push({name:'espace-centre'})
                this.dialogLoginCentre = false
            }else
                this.dialogLoginCentre = true
        },
        redirectContact(){
            this.$router.push({name:'contact'})
        },
    },
}
</script>

<style scoped>
footer
{
    width: 100%;

}
.address > * {
    color: whitesmoke;
}
h1,
h2,
h3,
h4,
h5,
h6,
li ,
p{
    color: black!important;
}
.abs-group{
    background-color: #1F5578!important;
}
.auto-bilan-systems{
    background-color: #89BA51!important;
}
.hoverClass {
    color: #F2811D!important;
    font-weight: 700;
}
.text-lemonway{
    font-family: Lato, sans-serif;
    color: #8f8f8f!important;
    font-size: 13px!important;
}
.hide-block{
    display: none;
}
</style>
