<template>
    <v-container fluid class="cookie ma-0 pa-0" v-if="!statusCookie">
            <v-col class="col-12 auto-bilan-systems">
                <v-row justify="center" align="center">
                    <v-col class="col-6">
                        <p class="ma-0 pa-0" style="color: white">{{text}}</p>
                    </v-col>
                    <v-col class="col-1">
                        <router-link v-bind:to="{name:'info-legal', params: { selected:'Cookies' } }">
                          <v-btn small class="mr-lg-2 mr-md-4 mr-sm-6 text-caption" color="white">
                            En savoir plus
                          </v-btn>
                        </router-link>
                    </v-col>
                    <v-col class="col-1">
                        <v-btn small class="ml-lg-2 ml-md-4 ml-sm-6 text-caption" color="white" @click="acceptCookie">
                            J'accepte
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
    </v-container>
</template>

<script>
    export default {
        name: "CookieMessageComponent",
        data(){
            return{
                text:"Ce site utilise des cookies pour vous offrir le meilleur service. En poursuivant votre navigation, vous acceptez l’utilisation des cookies.",
                statusCookie:false,
            }
        },
        methods:{
            acceptCookie(){
                localStorage.setItem('GPDR:accepted',true)
                this.statusCookie =true
            },
            isCookiesAccepted(){
                return localStorage.getItem('GPDR:accepted') == 'true' ? true : false
            }
        },
        mounted() {
            this.statusCookie = this.isCookiesAccepted()
        }
    }
</script>

<style scoped>
    .cookie{
        position: fixed;
        bottom: 0;
        z-index: 9999999;
    }
    .abs-group{
        background-color: #1F5578!important;
    }
    .auto-bilan-systems{
        background-color: #89BA51!important;
    }
    .consent{
        background-color: #3386A6;
    }
    .color-cookie{
        background-color: #EEF0F2;
    }
    .text-abs{
        color:#89BA51!important;
    }
    .text-abs-group{
        color:#1F5578!important;
    }
</style>
