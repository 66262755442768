const PoidsLourdsComponent = () => import(/* webpackChunkName: "PoidsLourds"*/"@/pages/PoidsLourdsComponent")
const VehiculeLegerComponent = ()=> import(/* webpackChunkName: "VehiculeLeger"*/"@/pages/VehiculeLegerComponent")
const CategorieLComponent = () => import(/* webpackChunkName: "CategorieL"*/"@/pages/CategorieLComponent")
const InformatiqueComponent = () => import(/* webpackChunkName: "PoidsLourds"*/"@/pages/InformatiqueComponent")
const AuditsComponent = () => import(/* webpackChunkName: "Audits"*/"@/pages/AuditsComponent")
const SocieteComponent = () => import(/* webpackChunkName: "Societe"*/"@/pages/SocieteComponent")
const HubComponent = () => import(/* webpackChunkName: "HomePage"*/"@/pages/HubComponent")
const FormationsComponent = () => import(/* webpackChunkName: "Formation"*/"@/pages/FormationsComponent")
const espaceCentre = () => import(/* webpackChunkName: "EspaceCentre"*/"@/pages/espace-centre")
const centre = () => import(/* webpackChunkName: "Centre"*/"@/components/espace-centre/centre")
const ContactUsForm = () => import(/* webpackChunkName: "ContactUsForm"*/"@/pages/ContactUsComponent")
const informationsLegales = () => import(/* webpackChunkName: "InformationsLegales"*/"@/pages/InformationsLegalesComponent")
const ActualitePage = () => import(/* webpackChunkName: "ActualitePage"*/"@/pages/ActualitePage")


export const professionalRoutes = [
    {
        path: '/',
        name: 'Pro',
        component: HubComponent
    },
    {
        path: '/contact',
        name:'contact',
        component: ContactUsForm
    },
    {
        path: "/espace-centre",
        name: "espace-centre",
        component: espaceCentre
    },
        {
        path: "/espace-centre/centre",
        name: "centre",
        component: centre
    },
    {
        path: "/hub-articles",
        name: "hub",
        component: HubComponent,
    },
    {
        path: "/poids-lourds/:nameUrl?",
        name: "poidsLourds",
        props: true,
        component: PoidsLourdsComponent,
    },
    {
        path: "/vehicules-legers/:nameUrl?",
        name: "vehiculesLegers",
        props: true,
        component: VehiculeLegerComponent
    },
    {
        path: "/categorie-l/:nameUrl?",
        name: "categoriel",
        props: true,
        component: CategorieLComponent
    },
    {
        path: "/informatique/:nameUrl?",
        name: "informatique",
        props: true,
        component: InformatiqueComponent
    },
    {
        path: "/audits/:nameUrl?",
        name: "audits",
        props: true,
        component: AuditsComponent
    },
    {
        path: "/qui-sommes-nous/:nameUrl?",
        name: "qui-sommes-nous",
        props: true,
        component: SocieteComponent
    },
    {
        path: "/formations/:nameUrl?",
        name: "formations",
        props: true,
        component: FormationsComponent
    },
    {
        path: '/informations-legales/:nameUrl?',
        props: true,
        name: 'info-legal',
        component: informationsLegales
    },
    {
        path: '/actualites/:nameUrl?',
        props: true,
        name: 'actualites',
        component: ActualitePage
    },
]
