<template>
    <v-row class="justify-end">
        <v-col
            class="py-2 col-12"
        >
            <v-btn-toggle
                tile
                group
                class="wrapper-button-swap"
            >
                <button class="button-particulier" @click="redirectClient('particulier')" >
                    Particulier
                </button>

                <button  @click="redirectClient('pro')">
                    Professionnel
                </button>

            </v-btn-toggle>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "SwapStatusClient",
    directives:{
        'add-class-hover':{
            bind(el, binding, vnode) {
                const { value="" } = binding;
                el.addEventListener('mouseenter',()=> {
                    el.classList.add(value)
                });
                el.addEventListener('mouseleave',()=> {
                    el.classList.remove(value)
                });
            },
            unbind(el, binding, vnode) {
                el.removeEventListener('mouseenter');
                el.removeEventListener('mouseleave')
            }
        }
    },
    data(){
        return{
            root:null,
        }
    },
    mounted(){
        this.root = document.documentElement
    },
    methods: {
        setColors(color) {
            this.root.style.setProperty('--options-orange', color)
            this.root.style.setProperty('--btn-color', color)
        },
        redirectClient(url){
            window.location.href = url === 'particulier' ? process.env.VUE_APP_URL_PARTICULIER : process.env.VUE_APP_URL_PRO
        },
    }
}
</script>

<style scoped>
.button-particulier:hover {
    opacity:1;
}
.button-particulier {
    background-color: var(--btn-color-particulier);
    opacity: 0.5;
    transition-property: opacity;
    transition-duration: .2s;
    transition-delay: .2s;
}
.button-opacity{
    opacity: 0.5;
}
.hover-opacity{
    opacity: 1;
}

</style>
